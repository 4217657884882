import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../icons/xoop-logo-pink.svg';

import '../styles/navbar.scss';

const Navbar = () => {
	return (
		<nav>
			<figure>
				<Link to='/'>
					<Logo />
				</Link>
			</figure>
			<div>
				<input type='checkbox' name='toggle' id='navCheckbox' />

				<label htmlFor='navCheckbox' id='navToggle' />
				<div id='menu'>
					<div>
						<div>
							<ul id='menu__items'>
								<li>
									<Link to='/'>
										<span>Home</span>
									</Link>
								</li>
								<li>
									<Link to='/work'>Our work</Link>
								</li>
								<li>
									<Link to='/team'>The team</Link>
								</li>
								<li>
									<Link to='/careers'>Careers</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
