import React from 'react';
import { ReactComponent as Postikko } from '../images/client/postikko.svg';
import { ReactComponent as Autodiili } from '../images/client/autodiili.svg';
import { ReactComponent as Skyscanner } from '../images/client/skyscanner.svg';
import { ReactComponent as Nike } from '../images/client/nike.svg';
import { ReactComponent as Makia } from '../images/client/makia.svg';
import { ReactComponent as Haltu } from '../images/client/haltu.svg';
import { ReactComponent as TH } from '../images/client/th.svg';
import { ReactComponent as BMW } from '../images/client/bmw.svg';
import { ReactComponent as Storaenso } from '../images/client/storaenso.svg';
import { ReactComponent as Tugenuf } from '../images/client/tug-enuff.svg';
import { ReactComponent as Tupperware } from '../images/client/tupperware.svg';
import { ReactComponent as SS } from '../images/client/ss.svg';
import { ReactComponent as Goofy } from '../images/client/goofy.svg';
import { ReactComponent as Costo } from '../images/client/costo.svg';
import { ReactComponent as FSF } from '../images/client/fsf.svg';
import { ReactComponent as Moomin } from '../images/client/moomin.svg';
import { ReactComponent as Arrow } from '../icons/arrow.svg';
import Navbar from './Navbar';

//style
import '../styles/work.scss';
import '../styles/workB.scss';
import '../styles/cards.scss';
import Footer from './Footer';

//images
const autodiili = require('../images/works/autodiili.png');
const skyft = require('../images/works/skyft.png');
const mp = require('../images/works/mp.png');
const kiito = require('../images/works/kiito.png');
const varian = require('../images/works/varian.png');

const Work = () => (
	<div id='main_view'>
		<Navbar />
		<div className='view'>
			<div className='inner'>
				<div className='welcome'>
					<div className='moon space red bottomLeft medium'>
						<div className='background' />
						<div className='planet'>
							<div className='shine' />
						</div>
					</div>
					<h1>
						We are your partner in creativity – making remarkable digital{' '}
						<span>products and services</span>.
					</h1>
				</div>
				<div className='work'>
					<div className='text'>
						<h1>All things web –</h1>
						<p>
							Our skilled team of designers, developers and analysts, are always
							ready and happy to help with turnkey solutions from concepts level
							to product release a handcrafted approach to building websites and
							digital services.
						</p>
					</div>
				</div>
				<div className='projects'>
					<a
						className='project__card card project viewCase'
						href='/cases/skyskyft'
						rel='noopener'
						style={{
							transform:
								'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
							background: '#102D78',
							color: '#FAFAFA',
						}}>
						<div className='shine' />
						<div className='details' style={{ minWidth: '24.5rem' }}>
							<h3>SkySkyft</h3>
							<p className='desc'>The clever way to find cheapest flights.</p>
							<p className='type'>React, Java, GraphQL, UX</p>
							<div className='slideArrow'>
								<Arrow className='arrow first' />
								<span>View Case</span>
								<Arrow className='arrow second' />
							</div>
						</div>
						<img alt='SkySkyft' src={skyft} />
					</a>
					<a
						className='project__card card project viewCase'
						style={{
							transform:
								'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
							background: '#DB2139',
							color: '#FAFAFA',
						}}
						href='/cases/autodiili'
						rel='noopener'>
						<div className='shine' />
						<div className='details'>
							<h3>Autodiili</h3>
							<p className='desc'>
								Trading platform for green and future cars.
							</p>
							<p className='type'>UX/UI, Angular, SPRING, iOS</p>
							<div className='slideArrow'>
								<Arrow className='arrow first' />
								<span>View Case</span>
								<Arrow className='arrow second' />
							</div>
						</div>
						<img alt='Autodiili' src={autodiili} />
					</a>
					<a
						className='project__card card project viewCase'
						href='/cases/kiito'
						rel='noopener'
						style={{
							transform:
								'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
							background: '#0537DA',
							color: '#003160',
						}}>
						<div className='shine' />
						<div className='details'>
							<h3>Kiito</h3>
							<p className='desc'>
								Voice transcriber and peer-to-peer language intranet support
								tool.
							</p>
							<p className='type'>UX/UI, iOS, Andriod</p>
							<div className='slideArrow'>
								<Arrow className='arrow first' />
								<span>View Case</span>
								<Arrow className='arrow second' />
							</div>
						</div>
						<img alt='Kiito' src={kiito} className='background height' />
					</a>
					<a
						className='project__card card project viewCase'
						href='/cases/mappointer'
						rel='noopener'
						style={{
							transform:
								'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
							background: '#191A1C',
							color: '#FAFAFA',
						}}>
						<div className='shine' />
						<div
							className='details'
							style={{ minWidth: '21rem', maxWidth: '25rem' }}>
							<h3>Mappointer</h3>
							<p className='desc'>Product locator for growing sales. search.</p>
							<p className='type'>UX/UI, WEB</p>
							<div className='slideArrow'>
								<Arrow className='arrow first' />
								<span>View Case</span>
								<Arrow className='arrow second' />
							</div>
						</div>
						<img alt='Mappointer' src={mp} style={{ marginLeft: '-80px' }} />
					</a>
					<a
						className='project__card card project viewCase'
						href='/cases/varian'
						rel='noopener'
						style={{
							transform:
								'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
							background: '#014FA1',
							color: '#FEFEFE',
						}}>
						<div className='shine' />
						<div
							className='details'
							style={{ minWidth: '21rem', maxWidth: '25rem' }}>
							<h3>Varian</h3>
							<p className='desc'>Unity App. search.</p>
							<p className='type'>UX/UI, WEB</p>
							<div className='slideArrow'>
								<Arrow className='arrow first' />
								<span>View Case</span>
								<Arrow className='arrow second' />
							</div>
						</div>
						<img alt='Varian' src={varian} style={{ marginLeft: '-80px' }} />
					</a>

					<a
						href='mailto:irik@xoop.fi'
						className='project__card card project viewCase'
						style={{ background: '#eee' }}>
						<p class='desc'>Collaborate and create a project with us</p>
						<div className='slideArrow'>
							<Arrow className='arrow first' />
							<span>Contact us</span>
							<Arrow className='arrow second' />
						</div>
					</a>
				</div>
				<div className='partners'>
					<div className='smallMoon space blue extraSmall'>
						<div className='background' />
						<div className='planet'>
							<div className='shine' />
						</div>
					</div>
					<div className='moon space blue topLeft medium'>
						<div className='background' />
						<div className='planet'>
							<div className='shine' />
						</div>
					</div>
					<div className='text'>
						<h1>We work with the best –</h1>
						<p>Say hello to some of our partners</p>
					</div>
					<div className='content'>
						<div className='partner'>
							<Skyscanner />
						</div>
						<div className='partner'>
							<Haltu />
						</div>
						<div className='partner'>
							<Moomin />
						</div>
						<div className='partner'>
							<FSF />
						</div>
						<div className='partner'>
							<Makia />
						</div>
						<div className='partner'>
							<Tupperware />
						</div>
						<div className='partner'>
							<Postikko />
						</div>
						<div className='partner'>
							<Autodiili />
						</div>
						<div className='partner'>
							<Tugenuf />
						</div>
						<div className='partner'>
							<Costo />
						</div>
						<div className='partner'>
							<Goofy />
						</div>
						<div className='partner'>
							<SS />
						</div>
						<div className='partner'>
							<Storaenso />
						</div>
						<div className='partner'>
							<Nike />
						</div>
						<div className='partner'>
							<BMW />
						</div>
						<div className='partner'>
							<TH />{' '}
						</div>
					</div>
				</div>
				<div className='footer'>
					<Footer />
				</div>
			</div>
		</div>
	</div>
);

export default Work;
