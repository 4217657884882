import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import './styles/App.css';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/Home';
import Work from './components/Work';
import Team from './components/Team';
import Careers from './components/Careers';
import Contact from './components/Contact';
import Autodiili from './components/cases/Autodiili';
import PageNotFound from './components/PageNotFound';
import Skyscrambler from './components/cases/Skyscrambler';
import Kiito from './components/cases/Kiito';
import Skannu from './components/cases/Skannu';

function App() {
	return (
		<Router>
			<ScrollToTop>
				<div className='app'>
					<Switch>
						<Route exact path='/' component={Home} />
						<Route path='/work' component={Work} />
						<Route path='/team' component={Team} />
						<Route path='/careers' component={Careers} />
						<Route path='/contact' component={Contact} />
						<Route path='/cases/autodiili' component={Autodiili} />
						<Route path='/cases/skyscrambler' component={Skyscrambler} />
						<Route path='/cases/kiito' component={Kiito} />
						<Route path='/cases/skannu' component={Skannu} />
						<Route component={PageNotFound} />
					</Switch>
				</div>
			</ScrollToTop>
		</Router>
	);
}

export default App;
