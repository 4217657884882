import React from 'react';

const TeamCard = ({
	person: {
		name,
		description: { desktop, mobile },
		img,
		phone,
		email,
	},
}) => (
	<div className='card astronaut'>
		<div className='shine' />
		<div className='inner2'>
			<h3>{name}</h3>
			<div className='desc'>
				<p className='desktop'>{desktop}</p>
				<p className='mobile'>{mobile}</p>
				<a className='email' href={`mailto: ${email}`}>
					{email}
				</a>
				<a className='phone' href='tel:+358 (0)409 629797'>
					{phone}
				</a>
			</div>
		</div>
		<img alt='Irik Henry' src={img} />
	</div>
);

export default TeamCard;
