import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Map from './Map';

//styles
import '../styles/contacts.scss';

const Contact = () => (
	<div id='main_view'>
		<Navbar />
		<Map isMarkerShown style={{ marginTop: '-16rem' }} />

		<div className='view' style={{ paddingTop: 0 }}>
			<div className='inner'>
				<div className='footer'>
					<Footer />
				</div>
			</div>
		</div>
	</div>
);

export default Contact;
