import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Arrow } from '../../icons/arrow.svg';
import Navbar from '../Navbar';
import Footer from '../Footer';

const adl1 = require('../../images/works/autodiili-assets/scene.png');
const adl2 = require('../../images/works/autodiili-assets/autodiili-02.jpg');
const adl3 = require('../../images/works/autodiili-assets/autodiili-08.png');
const adl4 = require('../../images/works/autodiili-assets/autodiili-11.png');
const adl5 = require('../../images/works/autodiili-assets/autodiili-00.png');

const Skannu = () => (
    
    <div id='main_view'>
    <Navbar />
    <div className='viewX' style={{ background: '#01081E', color: '#fff' }}>
        <div className='innerX'>
            <div className='heroHeader'>
                <h1>KIITO</h1>
                <h2>A New Age in Car Trade </h2>
            </div>
            <div className='largeImage' style={{ background: '#fff' }}>
                <img src={adl1} alt='Autodiili' />
            </div>
            <div
                className='centerBlock'
                style={{
                    background: 'rgb(26, 33, 50)',
                    color: 'rgb(255, 255, 255)',
                }}>
                <div className='inner block'>
                    <div className='content inViewBottom inView'>
                        <div className='block center'>
                            <h1 className=''>The Automobile Network</h1>
                            <p>
                                Beyond the focus on green and electric cars, Autodiili
                                provides real-time information on current trading trends in
                                the automobile e- commerce business in a state-of-the-art
                                simple, clear, easy-to-use but beautifully interface. A hub
                                for buyers, sellers and automobile enthusiast.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='textGridBlock'>
                <div className='inner' />
                <div className='blocks'>
                    <div className='block inViewLeft image inView'>
                        <img alt='Imag' src={adl2} />
                    </div>
                    <div className='block inViewRight inView'>
                        <h1>Exclusive, Yet Inclusive</h1>
                        <p>
                            Designed and developed in close pilot with automobile dealers,
                            cooperation with manufacturers and extensive study of their
                            everyday customer-users to achieve a cleverly made friendly
                            interface with a pristine view and experience of what future
                            automobile trading platform should be in a modern age of the
                            web, addressing the needs buyers, helping dealers, enabling
                            brands, staying relevant, fresh and inclusive.
                        </p>
                    </div>
                    <div className='block inViewRight image inView'>
                        <img alt='Imag' src={adl3} />
                    </div>
                    <div className='block inViewLeft image inView'>
                        <img alt='Imag' src={adl4} />
                    </div>
                </div>
            </div>
            <div className='textBlock' style={{ background: 'rgb(26, 33, 50)' }}>
                <div className='inner inViewBottom inView'>
                    <h1>Market Trend in Real-time</h1>
                    <p>
                        Combining the real-time access to market data from networks of
                        dealers and data on what are the buying trends, dealers, buyers
                        and enthusiasts can make better buying decisions, while dealers
                        can find a better way to meet the price point in the market
                        staying competitive and increasing their revenue. .
                    </p>
                    <img src={adl5} alt='Autodiili' />
                </div>
            </div>

            <div
                className='projectsBlock'
                style={{ background: 'rgb(22, 29, 43)' }}>
                <div className='inner'>
                    <div className='block'>
                        <h2 style={{ marginTop: '2.5rem' }}>Other projects</h2>
                        <Link to='/work' className='button slide button4'>
                            <Arrow className='arrow first' />
                            <span className='label'>See more projects</span>
                            <Arrow className='arrow second' />
                        </Link>
                    </div>
                    <div className='block'>
                        <div
                            className='projects browseCase'
                            style={{ position: 'relative', left: 0 }}>
                            <Link
                                to='/cases/autodiili'
                                className='project__card card project viewCase'
                                style={{
                                    transform:
                                        'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
                                    background: '#DB2139',
                                    color: '#FAFAFA',
                                }}
                                rel='noopener'>
                                <div className='shine' />
                                <div className='details'>
                                    <h3>Autodiili</h3>
                                    <p className='desc'>
                                        Trading platform for green and future cars.
                                    </p>
                                    <p className='type'>UX/UI, Angular, SPRING, iOS</p>
                                    <div className='slideArrow'>
                                        <Arrow className='arrow first' />
                                        <span>View Case</span>
                                        <Arrow className='arrow second' />
                                    </div>
                                </div>
                                <img
                                    alt='Autodiili'
                                    src={require('../../images/works/autodiili.png')}
                                />
                            </Link>
                            <a
                                className='project__card card project viewCase'
                                href='/cases/kiito'
                                rel='noopener'
                                style={{
                                    transform:
                                        'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
                                    background: '#0537DA',
                                    color: '#003160',
                                }}>
                                <div className='shine' />
                                <div className='details'>
                                    <h3>Kiito</h3>
                                    <p className='desc'>
                                        Voice transcriber and peer-to-peer language intranet
                                        support tool.
                                    </p>
                                    <p className='type'>UX/UI, iOS, Andriod</p>
                                    <div className='slideArrow'>
                                        <Arrow className='arrow first' />
                                        <span>View Case</span>
                                        <Arrow className='arrow second' />
                                    </div>
                                </div>
                                <img
                                    alt='Kiito'
                                    src={require('../../images/works/kiito.png')}
                                    className='background height'
                                />
                            </a>
                            <a
                                className='project__card card project viewCase'
                                href='/cases/mappointer'
                                rel='noopener'
                                style={{
                                    transform:
                                        'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
                                    background: '#191A1C',
                                    color: '#FAFAFA',
                                }}>
                                <div className='shine' />
                                <div
                                    className='details'
                                    style={{ minWidth: '21rem', maxWidth: '25rem' }}>
                                    <h3>Mappointer</h3>
                                    <p className='desc'>
                                        Product locator for growing sales. search.
                                    </p>
                                    <p className='type'>UX/UI, WEB</p>
                                    <div className='slideArrow'>
                                        <Arrow className='arrow first' />
                                        <span>View Case</span>
                                        <Arrow className='arrow second' />
                                    </div>
                                </div>
                                <img
                                    alt='Mappointer'
                                    src={require('../../images/works/mp.png')}
                                    style={{ marginLeft: '-80px' }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='view'>
                <div className='inner'>
                    <div className='footer'>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    )


export default Skannu
