import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import People from './People';
import '../styles/teamB.scss';
import '../styles/team.scss';

import TeamCard from './TeamCard';

const Team = () => (
	<div id='main_view'>
		<Navbar />
		<div className='view'>
			<div className='inner'>
				<div className='text'>
					<div className='moonDesktop space blue big'>
						<div className='background' />
						<div className='planet'>
							<div className='shine' />
						</div>
					</div>
					<div className='moonMobile space blue'>
						<div className='background' />
						<div className='planet'>
							<div className='shine' />
						</div>
					</div>
					<h1>
						<span>Humanely diverse </span> – designers, developers, creators and inventors united to execute at the highest level of expertise.
					</h1>
				</div>
				<div className='astronauts'>
					<div className='text'>
						<h1>The team –</h1>
						<p>Meet the core squad.</p>
					</div>
				</div>
				<div className='astronauts2'>
					{People.map(person => {
						return <TeamCard key={person.key} person={person} />;
					})}
				</div>
				<div className='footer'>
					<Footer />
				</div>
			</div>
		</div>
	</div>
);

export default Team;
