export default [{
        key: "xoopteam1",
        name: "Irikefe E-M",
        description: {
            desktop: "Founder and COO. Front developer and design lead with over 10 years of experience.",
            mobile: "Co-founder and Creative Director"
        },
        img: require("../images/people/irik.jpg"),
        phone: "+358 (0)40 9629797",
        email: "irik@xoop.fi"
    },
    {
        key: "xoopteam2",
        name: "Isaac Yao Tuagba",
        description: {
            desktop: "Passionate Developer committed to lifelong learning and motivated to build the next generation of applications that can empower businesses. ",
            mobile: "Fullstack Developer "
        },
        img: require("../images/people/isaac.jpg"),
        phone: "+358 (0)451265483",
        email: "isaac@xoop.fi"
    },
    {
        key: "xoopteam3",
        name: "Rudolf Schmidt",
        description: {
            desktop: "Lead Tech and Full-stack web developer with over 12 years of experience.",
            mobile: "Full Stack Web-Developer "
        },
        img: require("../images/people/rudolf.png"),
        phone: "+358 (0)40 9629797",
        email: "rudolf@xoop.fi"
    },
    {
        key: "xoopteam4",
        name: "Node Samson",
        description: {
            desktop: "Marketing research and data analyst. Experience google digital tool developer with over 6 years of experience.",
            mobile: "Data Scientist and DMO"
        },
        img: require("../images/people/samson.jpg"),
        phone: "+358 (0)40 9629797",
        email: "node@xoop.fi"
    },
    {
        key: "xoopteam5",
        name: "Tomi Kaukinen",
        description: {
            desktop: "An extensive experience about finance, funding strategies, having worked at JP Morgan and managed several investment assets. Currently, run Licence to Fail, licenceto.fail, the number 1 resource in Finland for start-up tips and tactics, extensive public funding help and stories from the world of entrepreneurship. Entrepreneur, early-stage start-up advisor, motivator and tools to scale and manage business operations.",
            mobile: "Financial Advisor"
        },
        img: require("../images/people/tomi2.jpg"),
        phone: "+358 (0)40 3564474",
        email: "tomi@xoop.fi"
    },
/*
    {
        key: "xoopteam6",
        name: "Mika Jalonen",
        description: {
            desktop: "Hands-on data science and engineering generalist. Over 15 years of Broad industry experience, including work in gaming, digital media, retail, fintech and health tech. Data Science / distributed computing: machine learning, bayesian stats and neural networks. Spark & Tensorflow.",
            mobile: "Tech Stack Advisor"
        },
        img: require("../images/people/avatar-man.png"),
        phone: "+358 (0)40 9629797 ",
        email: "mika@xoop.fi"
    },
*/
    {
        key: "xoopteam7",
        name: "Toni Hukkanen",
        description: {
            desktop: "Over 10 years of extensive experience across all sectors delivering creative work that is based on local understanding, but reflects high international standards. Brands worked with include Nike, Volkswagen, Finnair, McDonald, Unilever, E.ON, Electrolux, Nordea Bank, Kesko, Alko, Danske Bank, Fast Sports Nutrition, Aktia Bank, Osuuspankki,   Valmet Automotive, Bisnode, and more.",
            mobile: "Design and Strategy Advisor"
        },
        img: require("../images/people/toni.jpg"),
        phone: "+358 (0)40 4166051",
        email: "toni@xoop.fi"
    },
    {
        key: "xoopteam8",
        name: "You",
        description: {
            desktop: "We are looking for talented people to join our team. Feel free to call or leave a message.",
            mobile: "Marketing Intern"
        },
        img: require("../images/people/avatar-woman.png"),
        phone: "+358 (0)40 9629797",
        email: "info@xoop.fi"
    },
    {
        key: "xoopteam9",
        name: "You",
        description: {
            desktop: "We are looking for talented people to join our team. Feel free to call or leave a message.",
            mobile: "Business Development"
        },
        img: require("../images/people/avatar-man.png"),
        phone: "+358 (0)40 9629797",
        email: "info@xoop.fi"
    },
    {
        key: "xoopteam9",
        name: "You",
        description: {
            desktop: "We are looking for talented people to join our team. Feel free to call or leave a message.",
            mobile: "UX and Service Designer"
        },
        img: require("../images/people/avatar-woman.png"),
        phone: "+358 (0)40 9629797",
        email: "info@xoop.fi"
    }
];