import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../icons/arrow.svg';
import { ReactComponent as Arrow2 } from '../icons/arrow-second.svg';
import Navbar from './Navbar';
import Footer from './Footer';

//styles
import '../styles/careers.scss';
import '../styles/teamPictures.scss';

//Images
const img = require('../images/people/data-analyst.jpg');
const teamImg = require('../images/people/team-meeting.jpg');

const Careers = () => (
	<div>
		<Navbar />
		<div className='view'>
			<div className='inner'>
				<div className='heroText'>
					<div className='moon space blue bottomRight large'>
						<div className='background' />
						<div className='planet'>
							<div className='shine' />
						</div>
					</div>
					<h1>
						On site or remote, we hire for<span> character –</span> come along
						with your talent and greatness.
					</h1>
				</div>
				<div className='workplaces'>
					<div className='text'>
						<h1>Modern workspace – your space</h1>
						<p>
							Our studio office is centre of Tampere, cool and calm. You will
							find some of use sparring at the modern and stimulating Helsinki
							Think Corner, Startup Sauna and Helsinki BC office. Honestly, we
							relish your freedom and independent responsible remote working.
						</p>
					</div>
					<div className='content'>
						<div className='workplace'>
							<div className='image'>
								<img
									alt='workplace: Leave nothing for the way back. 👩🏿‍🚀&zwj;'
									src={require('../images/people/startup-write-ideas.jpg')}
								/>
							</div>
							<div className='desc'>
								<h3>
									Leave nothing for the way back.{' '}
									<span role='img' aria-label='workspace'>
										👩🏿‍🚀&zwj;
									</span>
								</h3>
								<p>
									Whatever the scale of the project, we never compromise on
									quality. Creatives and developers have the freedom to test new
									concepts, explore new tools, languages, frameworks and
									platforms. You are welcome to make an impact, take on
									responsibilities, and work at the joy of working in the team.
								</p>
							</div>
						</div>
						<div className='workplace'>
							<div className='image'>
								<img
									alt='workplace: Diversity – we truly look it. 🧔🏾👩🏾‍🔧👩🏼‍💻👨🏿‍💻👨🏻‍💻👱🏻‍♂'
									src={require('../images/people/team-hands.jpg')}
								/>
							</div>
							<div className='desc'>
								<h3>
									Diversity – we truly look it.{' '}
									<span role='img' aria-label='workspace'>
										🏠🧔🏾👩🏾‍🔧👩🏼‍💻👨🏿‍💻👨🏻‍💻👱🏻‍♂
									</span>
								</h3>
								<p>
									We take diversity seriously. We are a truly diverse group of
									experts and people, wherever you come from, your roots,
									talent, expertise and professionalism, come as you are, we
									will hire you for your character. We extend opportunities to
									those that are seeking breakthroughs. We invest in smart
									people that want to solve challenging problems.
								</p>
							</div>
						</div>
						<div className='workplace'>
							<div className='image'>
								<img
									alt='workplace: Tailored for you, by you. ✂️'
									src={require('../images/people/client-meeting.jpg')}
								/>
							</div>
							<div className='desc'>
								<h3>
									License to fail fast and learn.{' '}
									<span role='img' aria-label='rocket'>
										🚀
									</span>
								</h3>
								<p>
									At Xoop, we work in collaboration with other entrepreneurs to
									develop projects internally. By extension, offers you the
									opportunity to test ideas without the pressure that comes with
									client projects, and experiment, grow and horn your skills.
									There are several unexpected benefits that our internal
									projects bring to your career path, including the "license to
									fail", and fail fast. We are a balance between freedom and
									limitations.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='positions'>
					<div className='text'>
						<h1>Available positions</h1>
						<p>
							Want to work with great and ambitious people that will support
							you? Welcome. Growth your career path? Yes, please. Don’t like
							working with assholes? We don’t have such people and never hire
							them. Drinking pina colada every morning? That’s your cup of tea.
							Again come as you are, with our greatness, we hire for character.
						</p>
					</div>
					<div className='content'>
						<div className='position'>
							<div className='image'>
								<img
									alt='position: 🖥  Backend Developer'
									src={require('../images/people/team-in-work2.jpg')}
								/>
							</div>
							<div className='desc'>
								<div className='title'>
									<h3>Backend Developer</h3>
								</div>
								<p className='tasks'>
									All our products are backed by high-quality distributed
									systems built and maintained by our backend engineers. Become
									a part of that team and help us developing large-scale
									applications in Kotlin, Scala or Python. We advocate the use
									of proven and scalable technologies like Kafka, Elasticsearch
									and NoSQL databases, preferably using Docker and orchestrated
									with Kubernetes.
								</p>
								<a
									href='https://xoop.typeform.com/to/P5Qbwz'
									className='button2 small slide'>
									<Arrow className='arrow first' />
									<span className='label'>Apply </span>
									<Arrow className='arrow second' />
								</a>
							</div>
						</div>
						<div className='position'>
							<div className='image'>
								<img
									alt='position: 🖥  Backend Developer'
									src={require('../images/people/code-screen.jpg')}
								/>
							</div>
							<div className='desc'>
								<div className='title'>
									<h3>Frontend Developer</h3>
								</div>
								<p className='tasks'>
									All our products are backed by high-quality distributed
									systems built and maintained by our backend engineers. Become
									a part of that team and help us developing large-scale
									applications in Kotlin, Scala or Python. We advocate the use
									of proven and scalable technologies like Kafka, Elasticsearch
									and NoSQL databases, preferably using Docker and orchestrated
									with Kubernetes.
								</p>
								<a
									href='https://xoop.typeform.com/to/P5Qbwz'
									className='button2 small slide'>
									<Arrow className='arrow first' />
									<span className='label'>Apply </span>
									<Arrow className='arrow second' />
								</a>
							</div>
						</div>
						<div className='position'>
							<div className='image'>
								<img
									alt='position: Frontend Developer'
									src={require('../images/people/two-wokers.jpg')}
								/>
							</div>
							<div className='desc'>
								<div className='title'>
									<h3>Backend Developer</h3>
								</div>
								<p className='tasks'>
									All our products are backed by high-quality distributed
									systems built and maintained by our backend engineers. Become
									a part of that team and help us developing large-scale
									applications in Kotlin, Scala or Python. We advocate the use
									of proven and scalable technologies like Kafka, Elasticsearch
									and NoSQL databases, preferably using Docker and orchestrated
									with Kubernetes.
								</p>
								<a
									href='https://xoop.typeform.com/to/P5Qbwz'
									className='button2 small slide'>
									<Arrow className='arrow first' />
									<span className='label'>Apply </span>
									<Arrow2 className='arrow second' />
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='team'>
					<div className='text'>
						<h1>Behind the scenes</h1>
						<p>
							May we introduce ourselves? A well-attuned group of professionals
							enjoying working together.
						</p>
					</div>
					<div className='teamPictures'>
						<div className='block filter'>
							<img alt='Teampicture' src={teamImg} />
						</div>
						<div className='block filter'>
							<img
								alt='Teampicture'
								src={require('../images/people/women-team.jpg')}
							/>
						</div>
						<div className='block filter'>
							<img
								alt='Teampicture'
								src={require('../images/people/team-in-work.jpg')}
							/>
						</div>
						<div className='block'>
							<Link
								to='/team'
								className=' button2 slide2'
								style={{ width: '100%' }}>
								<span className='hack'>See the whole team</span>

								<Arrow className='arrow first arrowHack' />
								<Arrow className='arrow second arrowHack' />
							</Link>
						</div>
					</div>
				</div>

				<div className='footer'>
					<Footer />
				</div>
			</div>
		</div>
	</div>
);

export default Careers;
