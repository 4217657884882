import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../Navbar';
import { ReactComponent as Arrow } from '../../icons/arrow.svg';
import Footer from '../Footer';

//styles
import '../../styles/casesStyles/slideInBlock.scss';
//images
const ss1 = require('../../images/works/skyscrambler/ss.gif');
const ssmobile = require('../../images/works/skyscrambler/ss-mobile.gif');
const ss2 = require('../../images/works/skyscrambler/ss2.webp');
const ss3 = require('../../images/works/skyscrambler/ss3.webp');
const ss4 = require('../../images/works/skyscrambler/ss4.webp');
const skyft = require('../../images/works/skyft.png');
const skyft2 = require('../../images/works/skyft2.png');

const Skyscrambler = () => {
	return (
		<div id='main_view'>
			<Navbar />
			<div className='viewX'>
				<div className='innerX'>
					<div className='heroHeader' >
						<h1 style={{ color: '#E34F4F', fontFamily: 'SpaceGrotesk-Bold' }}>SKYSCRAMBLER</h1> 
						<h2>Travel Clever</h2>
					</div>
					<div className='largeImage'>
						<img src={ss4} alt='Sky' />
					</div>
					<div className='centerBlock'>
						<div className='inner left'>
							<img src={ssmobile} alt='' />
							<div className='content inViewBottom inView'>
								<div className='block center'>
									<h1>TEXT</h1>
									<p>TEXT DESCRIPTION</p>
								</div>
							</div>
						</div>
					</div>
					<div className='slideInBlock right flexEnd'>
						<div className='inner flexEnd inViewRight inView'>
							<img src={ss1} alt='' />
							<div className='content'>
								<h1>TEXT</h1>
								<p>TEXT DESCRIPTION</p>
							</div>
						</div>
					</div>
					<div className='threeBlock'>
						<div className='inner inViewBottom inView'>
							<div className='blocks'>
								<div className='block'>
									<img alt='' className='matSeIpad' src={ssmobile} />
								</div>
								<div className='block'>
									<h1>Text</h1>
									<p>Text</p>
									<ul>
										<li>Text</li>
										<li>Text</li>
										<li>Text</li>
										<li>Text</li>
										<li>Text</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div
						class='bookmarkBlock matSe'
						style={{
							background: 'rgb(255, 96, 2)',
							color: 'rgb(255, 255, 255)',
						}}>
						<div class='inner right'>
							<img alt='img' src={skyft2} />
							<div class='content inViewLeft inView'>
								<h1>Delivered to your doorstep</h1>
								<p class='text'>
									Route planning and distribution is part of the logistics
									platform as well. We redefined the delivery process by
									developing a new iOS app for truck drivers. The app provides
									drivers with order information and point-to-point navigation
									between stops. Real-time vehicle information is fed back into
									the logistics platform where supervisors can control all
									ongoing delivery routes and interact with the drivers.
									Integrated barcode and QR code scanning in the app or through
									3rd party scanners makes the delivery process even faster and
									more reliable.
								</p>
							</div>
						</div>
					</div>
					<div class='centerBlock'>
						<div class='inner block'>
							<div class='content inViewBottom inView'>
								<div class='block center'>
									<h1 class=''>Seeing the bigger picture.</h1>
									<p>
										Beside day to day logistics, the platform is even used by
										managers for strategic purposes. We have developed features
										on top of the platform to give managers insights in
										purchasing, sales and delivery performance and other reports
										which help decision-making executives.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class='largeImage'>
						<img alt='Img' src={skyft2} />
					</div>
					<div className='projectsBlock' style={{ background: '#fff' }}>
						<div className='inner'>
							<div className='block'>
								<h2 style={{ marginTop: '2.5rem' }}>Other projects</h2>
								<Link to='/work' className='button slide button4'>
									<Arrow className='arrow first' />
									<span className='label'>See more projects</span>
									<Arrow className='arrow second' />
								</Link>
							</div>
							<div className='block'>
								<div className='projects browseCase'>
									<Link
										to='/cases/autodiili'
										className='project__card card project viewCase'
										style={{
											transform:
												'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
											background: '#DB2139',
											color: '#FAFAFA',
										}}
										rel='noopener'>
										<div className='shine' />
										<div className='details'>
											<h3>Autodiili</h3>
											<p className='desc'>
												Trading platform for green and future cars.
											</p>
											<p className='type'>UX/UI, Angular, SPRING, iOS</p>
											<div className='slideArrow'>
												<Arrow className='arrow first' />
												<span>View Case</span>
												<Arrow className='arrow second' />
											</div>
										</div>
										<img
											alt='Autodiili'
											src={require('../../images/works/autodiili.png')}
										/>
									</Link>
									<a
										className='project__card card project viewCase'
										href='/cases/kiito'
										rel='noopener'
										style={{
											transform:
												'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
											background: '#0537DA',
											color: '#003160',
										}}>
										<div className='shine' />
										<div className='details'>
											<h3>Kiito</h3>
											<p className='desc'>
												Voice transcriber and peer-to-peer language intranet
												support tool.
											</p>
											<p className='type'>UX/UI, iOS, Andriod</p>
											<div className='slideArrow'>
												<Arrow className='arrow first' />
												<span>View Case</span>
												<Arrow className='arrow second' />
											</div>
										</div>
										<img
											alt='Kiito'
											src={require('../../images/works/kiito.png')}
											className='background height'
										/>
									</a>
									<a
										className='project__card card project viewCase'
										href='/cases/mappointer'
										rel='noopener'
										style={{
											transform:
												'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
											background: '#191A1C',
											color: '#FAFAFA',
										}}>
										<div className='shine' />
										<div
											className='details'
											style={{ minWidth: '21rem', maxWidth: '25rem' }}>
											<h3>Mappointer</h3>
											<p className='desc'>
												Product locator for growing sales. search.
											</p>
											<p className='type'>UX/UI, WEB</p>
											<div className='slideArrow'>
												<Arrow className='arrow first' />
												<span>View Case</span>
												<Arrow className='arrow second' />
											</div>
										</div>
										<img
											alt='Mappointer'
											src={require('../../images/works/mp.png')}
											style={{ marginLeft: '-80px' }}
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className='view'
					style={{ paddingTop: 0, background: '#fff', color: 'inherit' }}>
					<div className='inner'>
						<div className='footer'>
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Skyscrambler;
