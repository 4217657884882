import React from 'react';
import { compose, withProps } from 'recompose';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
} from 'react-google-maps';
const exampleMapStyles = [
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [
			{
				color: '#193341',
			},
		],
	},
	{
		featureType: 'landscape',
		elementType: 'geometry',
		stylers: [
			{
				color: '#2c5a71',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry',
		stylers: [
			{
				color: '#29768a',
			},
			{
				lightness: -37,
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'geometry',
		stylers: [
			{
				color: '#406d80',
			},
		],
	},
	{
		featureType: 'transit',
		elementType: 'geometry',
		stylers: [
			{
				color: '#406d80',
			},
		],
	},
	{
		elementType: 'labels.text.stroke',
		stylers: [
			{
				visibility: 'on',
			},
			{
				color: '#3e606f',
			},
			{
				weight: 0.1,
			},
			{
				color: '#eeeeee',
			},
		],
	},
	{
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#ffffff',
			},
		],
	},
	{
		featureType: 'administrative',
		elementType: 'geometry',
		stylers: [
			{
				weight: 0.3,
			},
			{
				color: '#1a3541',
			},
		],
	},
	{
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry',
		stylers: [
			{
				color: '#2c5a71',
			},
		],
	},
];
const Map = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=MAP-API-HERE&v=3.exp&libraries=geometry,drawing,places`,

		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: (
			<div
				style={{
					height: `70vh`,
					width: '100%',
					marginTop: '-15rem',
				}}
			/>
		),
		mapElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap,
)(props => (
	<GoogleMap
		defaultZoom={5.2}
		defaultCenter={{ lat: 55.6761, lng: 12.5683 }}
		options={{ styles: exampleMapStyles,  mapTypeControl: false, fullscreenControl: false }}>
		{props.isMarkerShown && [
			<Marker
				key='2'
				position={{ lat: 60.2028, lng: 24.9259 }}
				onClick={() => alert(55)}
			/>,
			<Marker key='3' position={{ lat: 51.5074, lng: 0.1278 }} />,
		]}
	</GoogleMap>
));

export default Map;
