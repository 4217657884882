import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTop from 'react-scrolltop-button';
import { ReactComponent as Logo } from '../icons/xoop-logo-pink.svg';
import { ReactComponent as Facebooklogo } from '../icons/facebook-logo-white.svg';
import { ReactComponent as Instagramlogo } from '../icons/instagram-logo-white.svg';
import { ReactComponent as Linkedlogo } from '../icons/linked-in-logo-white.svg';
import { ReactComponent as ButtonScroll } from '../icons/arrow-to-top.svg';
import '../styles/footer.scss';

const Footer = () => {
	return (
		<footer id='footer'>
			<div id='footer__section1'>
				<div id='footer__contact'>
					<div id='footer__talk__to__us'>
						<h2>Got something on</h2>
						<h2>your mind?</h2>
						<a href='mailto:irik@xoop.fi'>Get in touch with us!</a>
					</div>
				</div>
				<div id='footer__menu'>
					<ul>
						<li>
							<Link to='/'>Home</Link>
						</li>
						<li>
							<Link to='/work'>Our work</Link>
						</li>
						<li>
							<Link to='/team'>The team</Link>
						</li>
						<li>
							<Link to='/careers'>Careers</Link>
						</li>
						<li>
							<Link to='/contact'>Find Us </Link>
						</li>
					</ul>
				</div>
				<div id='footer__contact_address'>
					<p>
						<strong>Finland</strong>
					</p>
					<p>Rautatieläisenkatu 5</p>
					<p>00520 Helsinki</p>

					<p>
						<strong>United Kingdom</strong>
					</p>
					<p>30 Essoldo Queensbury</p>
					<p> HA8 5NU London </p>

					<p id='footer__enquries'>
						<strong>Enquiries</strong>
					</p>
					<a href='mailto:irik@xoop.fi'>info@xoop.fi</a>
				</div>
			</div>
			<hr />

			<div id='footer__socialMedia'>
				<figure>
					<Link to='/'>
						<Logo />
					</Link>
				</figure>
				<ul>
					<li>
						<Facebooklogo />
					</li>
					<li>
						<Instagramlogo />
					</li>
					<li>
						<Linkedlogo />
					</li>
				</ul>
			</div>
			<ScrollTop icon={<ButtonScroll />} className='buttonScroll' />
		</footer>
	);
};

export default Footer;
