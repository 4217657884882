import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../icons/arrow.svg';
import { ReactComponent as Arrow2 } from '../icons/arrow-second.svg';
import { ReactComponent as Worm } from '../images/worm.svg';
import '../styles/moon.scss';
import '../styles/home.scss';
import '../styles/button.scss';
import '../styles/worm.scss';

import Footer from './Footer';
import Navbar from './Navbar';

//images
const autodiili = require('../images/works/autodiili.png');
const skyft = require('../images/works/skyft.png');
const mp = require('../images/works/mp.png');
const kiito = require('../images/works/kiito.png');
const varian = require('../images/works/varian.png');

const Home = () => {
	return (
		<div id='main_view'>
			<Navbar />
			<div id='wrapper'>
				<section id='wrapper__welcome'>
					<div className='m firstView space blue middleRight '>
						<div className='background' />
						<div className='planet'>
							<div className='shine' />
						</div>
					</div>
					<div class='frame'>
						<div class='group1'>
							<Worm />
						</div>
					</div>
					<h1 id='wrapper__textH1'>
						<span>Xoop Design Studio –</span> your creative partner in strategic
						design and digital technology of the future today.
					</h1>
				</section>
				<section id='wrapper__information'>
					<div id='wrapper__information--textHeader'>
						<h1>We have got your back –</h1>
						<p>
							<span className='glitch' data-text='Str4t3gy'>
								Strategy,{' '}
							</span>
							<span className='glitch' data-text='Des1gn'>
								Design & {''}
							</span>
							<span className='glitch' data-text='Devel0pm3nt'>
								Development
							</span>
						</p>
						<div className='moon space red bottomLeft medium'>
							<div className='background' />
							<div className='planet'>
								<div className='shine' />
							</div>
						</div>
						<div className='moonSmall space blue topMiddle'>
							<div className='background' />
							<div className='planet'>
								<div className='shine' />
							</div>
						</div>
					</div>
				</section>

				<section id='wrapper__work'>
					<div id='wrapper__work--text'>
						<h1>Concept to final launch –</h1>
						<p id='work_description'>
							We are here for you. Forward-thinking, inventive, creative UI/UX
							approach to delivering high-performance digital product solutions
							that make life easier and bring value to clients and consumers.
						</p>
					</div>

					<div className='projects'>
						<a
							className='project__card card project viewCase'
							href='/cases/skyskyft'
							rel='noopener'
							style={{
								display: 'none',
								transform:
									'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
								background: '#102D78',
								color: '#FAFAFA',
							}}>
							<div className='shine' />
							<div className='details' style={{ minWidth: '24.5rem' }}>
								<h3>SkySkyft</h3>
								<p className='desc'>The clever way to find cheapest flights.</p>
								<p className='type'>React, Java, GraphQL, UX</p>
								<div className='slideArrow'>
									<Arrow className='arrow first' />
									<span>View Case</span>
									<Arrow className='arrow second' />
								</div>
							</div>
							<img alt='SkySKyft' src={skyft} />
						</a>
						<Link
							to='/cases/autodiili'
							className='project__card card project viewCase'
							style={{
								transform:
									'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
								background: '#DB2139',
								color: '#FAFAFA',
							}}
							rel='noopener'>
							<div className='shine' />
							<div className='details'>
								<h3>Autodiili</h3>
								<p className='desc'>
									Trading platform for green and future cars.
								</p>
								<p className='type'>UX/UI, Angular, SPRING, iOS</p>
								<div className='slideArrow'>
									<Arrow className='arrow first' />
									<span>View Case</span>
									<Arrow className='arrow second' />
								</div>
							</div>
							<img alt='Autodiili' src={autodiili} />
						</Link>
						<a
							className='project__card card project viewCase'
							href='/cases/kiito'
							rel='noopener'
							style={{
								transform:
									'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
								background: '#0537DA',
								color: '#003160',
							}}>
							<div className='shine' />
							<div className='details'>
								<h3>Kiito</h3>
								<p className='desc'>
									Voice transcriber and peer-to-peer language intranet support.
								</p>
								<p className='type'>UX/UI, iOS, Andriod</p>
								<div className='slideArrow'>
									<Arrow className='arrow first' />
									<span>View Case</span>
									<Arrow className='arrow second' />
								</div>
							</div>
							<img alt='kiito' src={kiito} className='background height' />
						</a>
						<a
							className='project__card card project viewCase'
							href='/cases/mappointer'
							rel='noopener'
							style={{
								transform:
									'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
								background: '#191A1C',
								color: '#FAFAFA',
							}}>
							<div className='shine' />
							<div
								className='details'
								style={{ minWidth: '21rem', maxWidth: '25rem' }}>
								<h3>Mappointer</h3>
								<p className='desc'>
									Product locator for growing sales. search.
								</p>
								<p className='type'>UX/UI, WEB</p>
								<div className='slideArrow'>
									<Arrow className='arrow first' />
									<span>View Case</span>
									<Arrow className='arrow second' />
								</div>
							</div>
							<img alt='Mappointer' src={mp} style={{ marginLeft: '-80px' }} />
						</a>
						<a
							className='project__card card project viewCase'
							href='/cases/varian'
							rel='noopener'
							style={{
								transform:
									'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
								background: '#014FA1',
								color: '#FEFEFE',
							}}>
							<div className='shine' />
							<div
								className='details'
								style={{ minWidth: '21rem', maxWidth: '25rem' }}>
								<h3>Varian</h3>

								<p className='desc'>Unity App.</p>
								<p className='type'>UX/UI, WEB</p>
								<div className='slideArrow'>
									<Arrow className='arrow first' />
									<span>View Case</span>
									<Arrow className='arrow second' />
								</div>
							</div>
							<img alt='Varian' src={varian} style={{ marginLeft: '-80px' }} />
						</a>
						<a
							className='project__card card project viewCase'
							href='/cases/skyskyft'
							rel='noopener'
							style={{
								transform:
									'perspective(0rem) translateZ(0rem) rotateX(0deg) rotateY(0deg)',
								background: '#102D78',
								color: '#FAFAFA',
							}}>
							<div className='shine' />
							<div
								className='details'
								style={{ minWidth: '21rem', maxWidth: '25rem' }}>
								<h3>SkySkyft</h3>
								<p className='desc'>The clever way to find cheapest flights.</p>
								<p className='type'>React, Java, GraphQL, UX</p>
								<div className='slideArrow'>
									<Arrow className='arrow first' />
									<span>View Case</span>
									<Arrow className='arrow second' />
								</div>
							</div>
							<img alt='SkySkyft' src={skyft} style={{ marginLeft: '-80px' }} />
						</a>
					</div>
					<div>
						<Link to='/work' className='button slide button2'>
							<Arrow className='arrow first' />
							<span className='label'>See more projects</span>
							<Arrow2 className='arrow second' />
						</Link>
					</div>
				</section>

				<section id='team'>
					<h1>Backstage, we are magical –</h1>
					<p>
						We are a group of professionals united in making better websites and
						applications. Passionate about what we do, we relish working with
						great people.
					</p>
					<div id='team__pictures'>
						<figure className='team_picture'>
							<img
								src={require('../images/people/african-gender-diversity.jpg')}
								alt='Team Members'
							/>
						</figure>
						<figure className='team_picture'>
							<img
								src={require('../images/people/african-dev-working.jpg')}
								alt='Team Members'
							/>
						</figure>
						<figure className='team_picture'>
							<img
								src={require('../images/people/handclap.jpg')}
								alt='Team Members'
							/>
						</figure>
						<div className='team_picture'>
							<Link to='/team' className=' button2 slide2 buttonWidth'>
								<span className='topHack'>See the whole team</span>
								<Arrow className='arrow first arrowHack' />
								<Arrow className='arrow second arrowHack' />
							</Link>
						</div>
					</div>
				</section>

				<Footer />
			</div>
		</div>
	);
};

export default Home;
