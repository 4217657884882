import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../icons/back-arrow.svg';

import '../styles/moon.scss';
import '../styles/home.scss';
import '../styles/button.scss';

import Footer from './Footer';
import Navbar from './Navbar';

const PageNotFound = () => {
	return (
		<div id='main_view'>
			<Navbar />
			<div className='view'>
				<div className='inner'>
					<div className='text'>
						<div className='notFoundbutton'>
							<img
								src={require('../images/page-404.svg')}
								alt='Page Not Found'
							/>
							<h2>Page Under Update</h2>

							<Link to='/work' class='button2 small slide reverse'>
								<Arrow className='arrow first' />
								<span class='label'>Go back</span>
								<Arrow className='arrow second' />
							</Link>
						</div>
						<div className='moonDesktop space blue big'>
							<div className='background' />
							<div className='planet'>
								<div className='shine' />
							</div>
						</div>
					</div>

					<div className='footer'>
						<Footer />
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageNotFound;
